import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../_environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, switchMap, of, forkJoin } from 'rxjs';
import { TableResponse, UrlParameters } from '../_model/shared';
import { isPlatformServer } from '@angular/common';
import { CustomerRatingService } from './CustomerRatingService';
import { Entitytype } from '../_model/redirect';
import { OrderService } from './OrderService';

@Injectable()
export class StaticpageService { // one service to rule them all

  constructor(
    private httpClient: HttpClient,
    private _customerratingService: CustomerRatingService,
    private _orderService: OrderService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {

  }
  // will be able to get all types of backend stored enities
  get(id: number, ent_type: string = 'STATICPAGE'): Observable<any> {
    const page_type = ent_type.toLowerCase();
    // TODO: it would be nice of all API's behave the same
    let base_url = environment.apiserver + `${page_type}bff/v1/${page_type}/`;
    if (ent_type == 'TAXONOMY') {
      base_url += 'node/';
    };
    let resolve_url = base_url;
    if (page_type === 'staticpage') {
      resolve_url += id;
    } else { // use GET list for all others  
      //const today = new Date().toISOString().split('T')[0];
      const assumedDeliverydate = this._orderService.assumedDeliverydate;
      resolve_url += `list?ids=${id}&from=${assumedDeliverydate}&to=${assumedDeliverydate}`;
    }
    let error_ratings = false
    let error_object = false
    let fork = forkJoin({
      object: this.httpClient.get(resolve_url).pipe(catchError(error => { error_object = true; return of(error) })),
      rating: this._customerratingService.getAggregatedRating(Entitytype[ent_type], id).pipe(catchError(error => { error_ratings = true; return of(error) }))
    })

    return fork.pipe(map((res) => {
      if (!error_object) {
        const object_or_list = <any>res.object;
        if (Array.isArray(object_or_list)) {
          if (object_or_list.length > 0) {
            const object = object_or_list[0];
            if (!error_ratings)
              object['rating'] = res.rating
            return object;
          } else {
            return null;
            // TODO: should look like a 404throwError(() => {return {status: 404}});
          }
        }
        if (!error_ratings)
          object_or_list['rating'] = res.rating
        return object_or_list;
      }
      throwError(() => 'Bad request');
    }))
  }

  find(parameters: UrlParameters, ent_type: string = 'STATICPAGE'): Observable<TableResponse<any>> {
    // TODO rename to just "find"
    const page_type = ent_type.toLowerCase();
    return this.httpClient.get(environment.apiserver + `${page_type}bff/v1/${page_type}/find` + parameters.toString()).pipe(
      map((response: Response) => <any>response)
      , catchError(this.handleError)
    );
  }

  private handleError(error: Response) {

    if (!!this && !!this.platformId && isPlatformServer(this.platformId)) {
      console.log(error);
      if (this == undefined) {
        console.log('this is undefined in staticpageservice')
      }
    }

    if (error.status === 400) {
      return throwError(() => 'Bad request');
    }
    return throwError(() => error || 'Server error');

  }

}
