import { Component, Inject, OnInit, PLATFORM_ID, OnDestroy } from '@angular/core';
import {AuthService} from '../../_service/AuthService';
import {Router} from '@angular/router';
import { GlobalService } from 'src/_service/GlobalService';
import { isPlatformBrowser } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '../../sharedModule/footer/footer.component';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    standalone: true,
    imports: [FooterComponent, TranslateModule]
})
export class LogoutComponent implements OnInit, OnDestroy {


  constructor(
    private loginService: AuthService,
    private router: Router,
    private _globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  timer: any;

  ngOnInit() {
    this._globalService.setTranslatedTitle('logout.logged-out');

    if (this.loginService.isAuthenticated()) {
      this.router.navigate(['user']);
    }
    if (isPlatformBrowser(this.platformId)) {
      this.timer = setTimeout(() => {
        this.navigateHome();
      }, 5000);
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }


  navigateHome() {
    this.router.navigate(['']);
  }


}
