<!-- will render any type of page -->
<div class="min-h-screen bg-e-white" id="content">
  <div *ngIf="!entity && !!loading && !isPrerendered">
    <div class="flex flex-col container-default justify-center items-center">
      <h2 class="pb-3">{{'common.loading' | translate}}</h2>
      <app-loader></app-loader>
    </div>
  </div>

  <div class="container" *ngIf="!entity && !loading">
    <div class="row my-5">
      <img src="assets/images/ekomenu-logo-rgb-rondje.svg" height="200" width="200">
      <h1 class="text-center pt-5">{{'common.not_found' | translate}}</h1>
      <div class="row">
        <div class="col-4 text-center">
          <a class="button-base" href="/">Home</a>
        </div>
      </div>
    </div>
  </div>
  <div clas="bg-e-white" *ngIf="!!entity && !loading">

    <div class="d-none" id="staticpagecontentloaded"></div>

    <app-cms-page *ngIf="entity_type === 'STORY'" [story]="entity"></app-cms-page>
    <div *ngIf="entity_type === 'STATICPAGE'" class="container-default mx-auto h-full-screen">
      <ngx-dynamic-hooks [content]="staticpageContent" [context]="dynamicContext"></ngx-dynamic-hooks>
    </div>
    <app-product *ngIf="entity_type === 'PRODUCT'"
                 [product]="entity"
                 (addOrderline)="addOrderline($event, entity, null)"
                 (quantityChange)="changeProductSelectionQty($event, entity)"
                 [orderlines]="orderlines?.value">

    </app-product>
    <ng-container *ngIf="entity_type === 'RECIPE'">
      <app-recipe [recipe]="entity"></app-recipe>
      <div class="relative container-default bg-e-white rounded-t-lg mb-[50px]">
        <h3 class="my-[25px] md:my-[50px]">{{'recipe.comparable_recipes' |translate}}</h3>
        <recipe-list *ngIf="entity?.lifestyles" [horizontal]="true" class="overflow-x-auto" [lifestyles]="[entity?.lifestyles.length >0 ? entity?.lifestyles[0]?.lifestyleid :null]" [deliverydate]="datenextweek" [pagesize]="5" [showallbtn]="false">
          <ng-template #recipe let-recipe>
            <recipecard class="flex items-stretch snap-center min-w-[340px]" [recipe]="recipe" [status]="'PROCESSED'">
            </recipecard>
          </ng-template>
        </recipe-list>
      </div>
    </ng-container>
    <app-lifestyle *ngIf="entity_type === 'LIFESTYLE'" [lifestyle]="entity"></app-lifestyle>
    <app-boxpage *ngIf="entity_type === 'BOXPAGE'" [boxpage]="entity"></app-boxpage>
    <app-box *ngIf="entity_type === 'BOX'"
             [box]="entity"
             (addOrderline)="addOrderline($event, null, entity)"
             (quantityChange)="changeProductSelectionQty($event, null, entity)"
             (personsChange)="changeBoxPersonQty($event, entity)"
             [orderlines]="orderlines?.value">


    </app-box>
  </div>
</div>

<button @fadeIn *ngIf="showScrollTop" title="Naar boven" class="fixed bottom-5 right-5 h-11 w-11 p-3 rounded-full bg-e-reg-green hover:bg-e-mid-green z-20" (click)="scrollToTop()">
  <span class="transition-colors duration-75 h-[16px] hover:mt-[-3px]">
    <img class="h-[17px] -rotate-90" alt="previous slide" src="./assets/images/icons/arrow_right.svg" />
  </span>
</button>

<app-footer></app-footer>
