import { Component, inject } from '@angular/core';
import {  NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/_service/Toast.service';

@Component({
  selector: 'app-toasts',
  standalone: true,
  imports: [NgbToastModule],
  templateUrl: './toasts-container.component.html',
  styleUrl: './toasts-container.component.scss'
})
export class ToastsContainerComponent {
  toastService = inject(ToastService);
}
