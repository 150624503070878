<div class="min-h-screen bg-e-white">
  <div class="container-default flex px-6 pt-6 pb-0 md:pb-6">
    <div class="flex flex-col w-full md:w-auto">
      <small class="flex flex-row flex-wrap md:my-6">
        <ng-template #breadcrumbtext><span class="col-auto ps-0">{{'allrecipes.allrecipes' |translate}} </span></ng-template>
        <a class="pr-[5px]" [routerLink]="'/recipes'" *ngIf="displaytype !== 'LIST';else breadcrumbtext">{{'allrecipes.allrecipes' |translate}}</a>
        <ng-container *ngIf="displaytype === 'LIFESTYLE' && shownLifestyle"><span class="pr-[5px]">></span> <span class="col-auto ps-0 ">{{shownLifestyle?.name | capitalizefirst}} {{'recipe.recipes' | translate| lowercase}}</span></ng-container>
        <ng-container *ngIf="displaytype !== 'LIFESTYLE' && shownLifestyle"><span class="pr-[5px]">></span> <a [routerLink]="'/recipes/' + shownLifestyle.name.replace(' ', '-').toLowerCase()" class="col-auto ps-0 ">{{shownLifestyle?.name | capitalizefirst}} {{'recipe.recipes' | translate| lowercase}}</a></ng-container>
        <ng-container *ngIf="displaytype === 'RECIPESEARCH'"><span class="pr-[5px]">></span> <span class="col-auto ps-0 text-capitalize-first">{{'recipesearch.results_for' | translate}} {{searchedValue.value}}</span></ng-container>
        <ng-container *ngIf="displaytype === 'INGREDIENTSEARCH'"><span class="pr-[5px]">></span> <span class="col-auto ps-0 text-capitalize-first">{{'recipesearch.results_for_ingredientsearch' | translate}}</span></ng-container>
      </small>
      <app-toggle [(ngModel)]="ingredientSearchActive"> {{'recipesearch.search_by_ingredient' | translate}}</app-toggle>
      <div @collapse *ngIf="!ingredientSearchActive">
        <div class="form-field">
          <label>{{'recipe.search_recipe' | translate}}</label>
          <input type="text" [formControl]="searchRecipe" (keydown.enter)="onEnter()" />
          <span><app-loader *ngIf="recipeSearchLoading && searchRecipe.value?.length >= 2" diameter="25"></app-loader></span>
          <button class="absolute right-[15px] top-[45px] z-[1]" *ngIf="!recipeSearchLoading && searchRecipe.value?.length >= 2" (click)="searchRecipe.setValue(null); onEnter();"><i class="bi bi-x-lg"></i></button>
        </div>
      </div>

      <div @collapse *ngIf="ingredientSearchActive">
        <div class="form-field ">
          <label>{{'recipesearch.search_by_ingredient' | translate}}</label>
          <input type="text" placeholder="{{'recipesearch.add_ingredient' |translate}}" #fruitInput [formControl]="ingredientCtrl" (keydown.enter)="add()">
        </div>
        <div class="flex flex-row flex-wrap">
          <app-chip *ngFor="let ingredient of ingredients.value" [clickable]="false">
            {{ingredient}}<button (click)="remove(ingredient)"><i class="bi bi-x"></i></button>
          </app-chip>
        </div>
      </div>
    </div>
  </div>

  <div class="container-default flex px-6 pb-6 pt-0 md:pt-6 overflow-hidden">
    <div class="w-full">
      <p class="hidden md:block">{{'allrecipes.recipes_per_lifestyle' | translate}}</p>
      <div class="hidden md:flex flex-row flex-wrap mb-4">
        <ng-container *ngFor="let style of lifestyles ; let i=index">
          <a [routerLink]="shownLifestyle?.id === style.id? '/recipes': '/recipes/'+ style.name.replace(' ', '-').toLowerCase()" queryParamsHandling="merge">
            <app-chip colorscheme="contrast" [selected]="shownLifestyle?.id === style.id" id="lifestyle-{{i}}">
              {{style.name | capitalizefirst}} {{'recipe.recipes' | translate | lowercase}}
            </app-chip>
          </a>
        </ng-container>
      </div>
      <div class="form-field md:hidden w-full">
        <label>{{'allrecipes.recipes_per_lifestyle' | translate}}</label>
        <select [(ngModel)]="shownLifestyleid" class="w-full" (change)="navigateToLifestyle()">
          <option [value]="null">{{'common.all' |translate}}</option>
          <option *ngFor="let style of lifestyles ; let i=index" [value]="style.id">
            {{style.name | capitalizefirst}} {{'recipe.recipes' | translate | lowercase}}
          </option>
        </select>
      </div>
      <p class="hidden mt-4 md:block">{{'allergen.allergens' | translate}}</p>
      <div class="hidden md:flex flex-row flex-wrap">
        <ng-container *ngFor="let allergen of allergens; let i=index">
          <app-chip colorscheme="contrast" (click)="toggleAllergen(allergen)" [selected]="isActiveAllergen(allergen)" id="allergen-{{i}}">
            {{'allergen.' + allergen | translate}}
          </app-chip>
        </ng-container>
      </div>
      <div class="form-field md:hidden">
        <label>{{'allergen.allergens' | translate}}</label>
        <mat-select class="flex w-full"  color="success" [formControl]="allergensCtrl" id="allergens" matInput multiple placeholder="{{'allergen.allergens' | translate}}" type="text">
          <mat-option *ngFor="let allergen of allergens" [value]="allergen" class="mat-success">
            {{'allergen.' + allergen |translate}}
          </mat-option>
        </mat-select>
      </div>
      
    </div>
  </div>

  <ng-container [ngSwitch]="displaytype">
    <ng-container *ngSwitchCase="'LIFESTYLE'">
      <div class="container-default flex flex-col justify-center mb-3 mt-3 p-6">
        <h1>
          <!-- has a fallback if there isnt a translation set -->
          <ng-container #translatetitle>{{'allrecipes.title_' + shownLifestyle?.id | translate | capitalizefirst}}</ng-container>
          <!--<ng-container *ngIf="translatetitle.previousSibling.data === ''">{{shownLifestyle.name | capitalizefirst}} {{'recipe.recipes' | translate}}</ng-container>-->
        </h1>
        <div>
          <!-- has a fallback if there isnt a translation set -->
          <ng-container #translatetext>{{'allrecipes.' + shownLifestyle?.id | translate | capitalizefirst}}</ng-container>
          <!--<span [innerHtml]="shownLifestyle?.description" *ngIf="translatetext.previousSibling.data === ''"></span>-->
        </div>

      </div>
      <div class="row mt-3 mb-3 justify-center">
        <ng-container *ngTemplateOutlet="lifestylebar;context:{lifestyle:shownLifestyle, lifestyleslist:false}"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'RECIPESEARCH'">
      <div class="row justify-content-center mb-3 mt-5 ">
        <div class="container-default">
          <h1>{{'recipesearch.results_for' | translate}} {{searchedValue.value}}</h1>
        </div>
      </div>
      <div class="row mt-3 mb-3 justify-center">
        <ng-container *ngTemplateOutlet="lifestylebar;context:{lifestyle:shownLifestyle, lifestyleslist:false}"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'INGREDIENTSEARCH'">
      <div class="row justify-content-center mb-3 mt-5 ">
        <div class="container-default">
          <h1>{{'recipesearch.results_for_ingredientsearch' | translate}}</h1>
        </div>
      </div>
      <div class="row mt-3 recipes mb-3 justify-content-center">
        <ng-container *ngTemplateOutlet="lifestylebar;context:{lifestyle:shownLifestyle, lifestyleslist:false}"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'LIST'">
      <div class="container-default md:p-[25px] mt-5">
        <h1>{{'allrecipes.allrecipes'| translate}}</h1>
        <div class="col-auto">{{'allrecipes.subtext'| translate}}</div>
      </div>
      <ng-container *ngFor="let lifestyle of lifestyles">
        <div class="container-default">
          <div class="flex flex-row flex-wrap items-center justify-between mb-[25px] md:mb-0 md:p-[25px]">
            <h2>
              <!-- has a fallback if there isnt a translation set -->
              <ng-container #translatetitle2>{{'allrecipes.title_' + lifestyle?.id | translate | capitalizefirst}}</ng-container>
              <!--<ng-container *ngIf="translatetitle2.previousSibling.data === ''">{{lifestyle.name | capitalizefirst}} {{'recipe.recipes' | translate}}</ng-container>-->
            </h2>
            <a class="chip bg-white rounded-full" [routerLink]="'/recipes/' + lifestyle.name.replace(' ', '-').toLowerCase()" queryParamsHandling="merge">{{'allrecipes.showall' |translate}} {{lifestyle.name}} {{'recipe.recipes' | translate}}</a>
          </div>
          <ng-container *ngTemplateOutlet="lifestylebar;context:{lifestyle:lifestyle, lifestyleslist:true}"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #lifestylebar let-lifestyle="lifestyle" let-lifestyleslist="lifestyleslist">
    <div class="container-default relative flex flex-col p-0 p-[25px] bg-e-white" #recipelist>

      <recipe-list class="overflow-x-auto"
                   [cols]="4"
                   [horizontal]="displaytype === 'LIST'"
                   [recipes]="recipes"
                   (shownRecipesChange)="shownRecipeCnt = $event.length"
                   [lifestyles]="[lifestyle?.id]"
                   [scrollload]="!lifestyleslist"
                   (scrollloadChange)="loadNextRecipes()"
                   [pagesize]="5"
                   [loading]="recipesloading"
                   [allergens]="allergensCtrl.value"
                   id="recipelist-{{lifestyle?.id}}">
        <ng-template #recipe let-recipe>
          <recipecard [recipe]="recipe"
                      [status]="'PROCESSED'"
                      class="flex items-stretch snap-center min-w-[340px]"
                      [selectedallergens]="allergensCtrl.value">
          </recipecard>
        </ng-template>
      </recipe-list>

      <!-- nothing found -->
      <ng-container *ngIf="shownRecipeCnt === 0 && !recipesloading">
        <div class="flex flex-col mt-5 mb-5 justify-center" id="busychef">
          <img alt="Nothing here exept a egg" class="max-h-[75px]" src="assets/images/icons/lifestyle/icon_leefstijl_13.svg" />
          <span class="ms-4 me-4 mb-5 text-center block mt-3" *ngIf="displaytype === 'RECIPESEARCH'">{{'recipesearch.no_menu_with_this_name'| translate}}</span>
          <span class="ms-4 me-4 mb-5 text-center block mt-3" *ngIf="displaytype === 'INGREDIENTSEARCH'">{{'recipesearch.no_menu_with_these_ingredients'| translate}}</span>
        </div>
      </ng-container>
    </div>

  </ng-template>


  <!-- template for the bottom drawer dialog -->
  <ng-template #bottomdrawerTemplateRef>
    <div class=" p-[25px] rounded-md">
      <!--<a class="button rounded-full" [routerLink]="/personalmenu">
        <span>{{'mpm.find_your_menu' | translate}}</span>
      </a>-->
      <a class="button-base rounded-full" routerLink="/{{'singleorder.organicwebshop_url' |translate}}">
        <span>{{'singleorder.goto_webshop' | translate}}</span>
      </a>
    </div>
  </ng-template>
</div>

<span class="hidden grid-cols-1 border-e-dark-green md:grid-cols-2 lg:grid-cols-3 grid-cols-4 bg-e-reg-green opacity-50"></span>

<app-footer></app-footer>
