<div class="toast-container mt-2 px-2 fixed z-50 top-0 left-[50%]" aria-live="polite"
    aria-atomic="true">
    @for (toast of toastService.toasts; track toast; let i = $index) {
    <ngb-toast [class]="toast.classname + ' rounded-lg shadow-md text-base w-[750px]'" [autohide]="true" [delay]="toast.duration || 5000"
        (hidden)="toastService.remove(i)">

        <div class="flex flex-row justify-between gap-1">
            <span>{{toast.content}}</span>
            @if(toast.buttontext){
            <button (click)="toastService.remove(i)">{{toast.buttontext}}</button>
            }
        </div>
    </ngb-toast>
    }
</div>