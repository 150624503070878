import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CouponcodeService } from 'src/_service/CouponcodeService';


@Component({
  selector: 'app-tellafriendresult',
  template: '<h1>You have a nice friend!</h1><p>Enjoy the discount on your Ekomenu box</p>',
  standalone: true
})
export class TellafriendResultComponent implements OnInit {

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private _couponcodeService: CouponcodeService
  ) {

  }
  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
    ).subscribe(queryParams => {

      this._couponcodeService.setCouponcode(queryParams.couponcode, null, queryParams.email)
      // const couponcode = new CouponcodeCode(null, queryParams.couponcode, null, null, true, queryParams.email, null)
      // this._localStorage.setObject('couponcode', couponcode)

      // this._toastService.open(this._translate.instant('couponcode.code_added_to_session'), this._translate.instant('common.ok_dismiss'), Snackbarformat);

      this.router.navigate(['./personalmenu']);

    });

  }

}
