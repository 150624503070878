import { animate, state, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';
import { DrawerService } from 'src/_service/DrawerService';
import { environment } from '../_environments/environment';
import { Countrycode, Preferredlanguage, Sourcetype } from '../_model/shared';
import { User } from '../_model/user';
import { AuthService } from '../_service/AuthService';
import { BoxService } from '../_service/BoxService';
import { CouponcodeService } from '../_service/CouponcodeService';
import { DeliverydayService } from '../_service/DeliverydayService';
import { GlobalService } from '../_service/GlobalService';
import { LifestyleService } from '../_service/LifestyleService';
import { LinkService } from '../_service/LinkService';
import { OrderService } from '../_service/OrderService';
import { ProductService } from '../_service/ProductService';
import { RecipeService } from '../_service/RecipeService';
import { StaticpageService } from '../_service/StaticpageService';
import { SubscriptionService } from '../_service/SubscriptionService';
import { WINDOW } from '../_service/WindowService';
import { DrawerComponent } from './drawer/drawer.component';
import { TopMenuComponent } from './topmenu/topmenu.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';
import localeEN from '@angular/common/locales/en';
import localeFR from '@angular/common/locales/fr';
import localeNL from '@angular/common/locales/nl';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('totalMenu', [
            state('true', style({
                width: '200px'
            })),
            state('false', style({
                width: '55px'
            })),
            transition('false <=> true', animate('300ms linear')),
        ]),
        trigger('totalWidth', [
            state('true', style({
                marginLeft: '200px'
            })),
            state('false', style({
                marginLeft: '55px'
            })),
            transition('false <=> true', animate('300ms linear')),
        ]),
        trigger('noMenu', [
            state('true', style({
                marginLeft: '0px'
            })),
            transition('false <=> true', animate('300ms linear')),
        ])
    ],
    standalone: true,
  imports: [ 
    TopMenuComponent, DrawerComponent, ToastsContainerComponent,
    RouterOutlet

  ],
  providers: [
    CouponcodeService, OrderService, SubscriptionService, RecipeService, BoxService, ProductService, DrawerService, StaticpageService, LinkService, LifestyleService, DeliverydayService]
})
export class AppComponent implements OnInit, OnDestroy {

  env = { ...environment };

  currentUser: User;

  preferredlanguage = new FormControl('');
  languages: string[];

  routeEventSubscription: any;

  unsubscribe = new Subject<void>();

  constructor(
    private _authService: AuthService,
    private _globalService: GlobalService,
    private _translateService: TranslateService,
    private _couponcodeService: CouponcodeService,
    private _drawerService: DrawerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {

    registerLocaleData(localeNL, 'nl');
    registerLocaleData(localeFR, 'fr');
    registerLocaleData(localeEN, 'en');

    _translateService.addLangs(['en', 'nl', 'fr']);
    _translateService.setDefaultLang('en');

    //gets it by itself from the localstorage and not via the globalservice because otherwise you have a circular dependency
    let globalLang = null
    let tld = 'nl'
    if (isPlatformBrowser(this.platformId)) {
      globalLang = JSON.parse(localStorage.getItem('preferredlanguage')) as Preferredlanguage;
      const baseUrl = !!window && !!window.location && !window.location.origin.includes('localhost') ? window.location.origin : 'www.ekomenu.nl';
      tld = baseUrl.slice(baseUrl.length - 2, baseUrl.length);
    }

    //gets the available translations
    const langs = this._translateService.langs.join("|");
    const rex = new RegExp(langs);
    if (globalLang) {
      //checks if the translation is available and otherwise uses english
      _translateService.use(globalLang.language.toLocaleLowerCase().match(rex) ? globalLang.language.toLocaleLowerCase() : 'nl');
    } else {
      let browserlang = _translateService.getBrowserLang();
      if (!!browserlang && browserlang.match(rex)) {
        _translateService.use(browserlang);
      } else {
        _translateService.use('nl');
      }
    }

    _translateService.onLangChange.subscribe(lang => {
      if (isPlatformBrowser(this.platformId))
        document.querySelector('html').setAttribute('lang', lang.lang.toLowerCase() + '-' + tld)
    })



  }

  isAuthenticated = this._authService.isAuthenticatedSubject.asObservable()

  ngOnInit() {

    this.languages = this._globalService.getLanguagecodes();
   
    this.currentUser = this._authService.getCurrentUser();
    this._authService.isAuthenticatedSubject.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.currentUser = this._authService.getCurrentUser();
    })

    const lang = this._globalService.getLanguage();

    this.preferredlanguage.setValue(lang.toUpperCase());
    this.preferredlanguage.valueChanges.subscribe(value => {
      // @ts-ignore
      this._globalService.setLanguage(Sourcetype.CUSTOMER, value);
      setTimeout(() => {
        this.reloadPage(); // prevent the reload before we have completed the lang pref storage
      }, 0);      
    });

    // sets the pricecountrycode based on the tld
    const countrycodes = this._globalService.getCustomercountrycodes();
    const tld = this._globalService.getTld()
    const tldCountrycode = countrycodes.filter(c => c.toLowerCase() === tld.toLowerCase())[0];
    this._globalService.setPricecountry(Sourcetype.URL, Countrycode[tldCountrycode || 'NL'])

    this._translateService.onLangChange.subscribe(val => {
      //console.log(val);
      this.preferredlanguage.setValue(val.lang.toUpperCase(), { emitEvent: false });
    });

    this.routeEventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this._drawerService.dismiss();
      }
      if (event instanceof NavigationEnd) {
        const url = event['urlAfterRedirects'].split(/[\/\?]/);
        const url_start = url[1];
        // push lang to the data layer
        try {
          // these calls will fail in non-browser versions, but we should not care about this there anyway
          const page_view = { // there is a lot of different pageview event formats partly documentent on the web,
            // GTM sends in history change events that could be used to measure pageviews also
            event: 'virtualPageview', // or  "Pageview" ??
            lang: this._globalService.getLanguage(),
            pageUrl: event.urlAfterRedirects,
            pageTitle: event.urlAfterRedirects, // page title is not  knowns here ...
            //tid: this.fingerprint
          };
          this.window['dataLayer'].push(page_view);
        } catch (e) {
          // console.warn('datalayer access failed:');
          // console.warn(e);
        }
       

      }
    });

    this.activatedRoute.queryParams.pipe(
      filter(params => params.code)
    ).subscribe(params => {

      this._couponcodeService.setCouponcode(params.code);

      let codeparam = { code: null }
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: codeparam,
        queryParamsHandling: 'merge'
      })
    })
  }

  
  ngOnDestroy() {
    this.routeEventSubscription.unsubscribe();
    this.unsubscribe.next();
  }

  reloadPage() {
    if (isPlatformBrowser(this.platformId)) {
      this.window?.location.reload();
    }
  }

  logout() {
    this._authService.logout();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.router.navigate(['logout']);
      }, 0);
    }
  }


}
