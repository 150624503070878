import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../_service/Toast.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CouponcodeService } from '../../_service/CouponcodeService';
import { Snackbarerrorformat, Snackbarformat } from '../../_theme/snackbarformats';

//THIS COMPONENT MOSTLY ONLY FOR RESOLVING MAGENTO COUPONCODES
//normal couponcodes are handled in the app.component
@Component({
  selector: 'app-couponcode',
  template: 'the couponcode is added ',
  standalone: true,
  providers: [CouponcodeService]
})
export class CouponcodeComponent implements OnInit {

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private _couponcodeService: CouponcodeService,
    private _translate: TranslateService,
    private _toastService: ToastService,

  ) {

  }
  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      //if the code is in the url, the code doesnt have to get set by the id
      if (params.code) {
        const codes = params.code.split(',');
        //console.log('magento code', params.code, atob(codes[0]));
        this._couponcodeService.setCouponcode(atob(codes[0]));

        this.navigate(params);

      } else if (params.magentoid) {
        const ids = params.magentoid.split(',');
        /*ids.forEach(id => {*/
        const id = ids[0];
        if (id && id !== '') {
          // console.log('couponcode magentoidid', id, atob(id));
          this._couponcodeService.getMagentoCouponcode(Number(atob(id))).subscribe(result => {
            this.navigate(params);
          }, error => {
            this._translate.get(['couponcode.error_in_checking_code', 'common.ok_dismiss']).subscribe(result => {
              this._toastService.open
                (result['couponcode.error_in_checking_code'], result['common.ok_dismiss'], Snackbarerrorformat);
            })

            this.navigate(params);
          });
        }
        // })
      }

    });

    //this.activatedRoute.queryParams.pipe(
    //).subscribe(queryParams => {
    //  // console.log('queryparams', queryParams);
    //});

  }

  navigate(params: any) {
    if (params.to) {
      this.router.navigateByUrl('/' + params.to)
      //window.location.replace(window.location.origin + '/' + params.to);
    } else {
      this.router.navigateByUrl('/')

    }
  }

}
