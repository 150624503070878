import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './_environments/environment';
import { appConfig } from './app.config';
import { AppComponent } from './appModule/app.component';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  console.log('page loaded')
})

bootstrapApplication(AppComponent,
  appConfig
).catch(err => console.error(err));

