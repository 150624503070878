import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {AuthService} from '../../_service/AuthService';

@Injectable()
export class LogoutResolver  {
  constructor(
    private router: Router,
    private _authService: AuthService
  ) {
  }


  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (this._authService.isAuthenticated()) {
      this.router.navigate([this.router.url]);
      return EMPTY;
    } else {
      return of(true);
    }
  }

}
