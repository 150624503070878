import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../sharedModule/login/login.component';
import { AuthGuardService } from '../_service/AuthGuardService';
import { AllrecipesComponent } from './allrecipes/allrecipes.component';
import { CouponcodeComponent } from './couponcode/couponcode.component';
import { LogoutComponent } from './logout/logout.component';
import { LogoutResolver } from './logout/logout.resolver';
import { PaymentSuccessComponent } from './paymentsuccess/paymentsuccess.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { StaticpageComponent } from './staticpage/staticpage.component';
import { TellafriendResultComponent } from './tellafriendresult/tellafriendresult.component';
import { WeekrecipesComponent } from './weekrecipes/weekrecipes.component';
import { ContactformComponent } from '../sharedModule/contactform/contactform.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent, resolve: { data: LogoutResolver } },
  { path: 'resetpassword', component: ResetpasswordComponent },
  { path: 'taf', component: TellafriendResultComponent },
  { path: 'menus', component: WeekrecipesComponent },
  { path: 'contactform', component: ContactformComponent },
  {
    path: 'recipes',
    children: [
      { path: '', component: AllrecipesComponent },
      { path: ':lifestyle', component: AllrecipesComponent }
    ]
  },

  {
    path: 'ecwcoupon/index/index/ad/:magentoid',
    children: [
      { path: '', component: CouponcodeComponent, pathMatch: "full" },
      {
        path: 'r', children: [
          { path: '', component: CouponcodeComponent, pathMatch: "full" },
          { path: ':to', component: CouponcodeComponent, }
        ]
      },
      {
        path: 'c', children: [
          { path: '', component: CouponcodeComponent, pathMatch: "full" },
          {
            path: ':code', children: [
              { path: '', component: CouponcodeComponent, pathMatch: "full" },
              {
                path: 'r', children: [
                  { path: '', component: CouponcodeComponent, pathMatch: "full" },
                  { path: ':to', component: CouponcodeComponent, }
                ]
              }
            ],
          }
        ]

      }
    ]
  },

  { path: 'twikey/payment/success', component: PaymentSuccessComponent },

  { path: 'user', loadChildren: () => import('../userModule/user.module').then(m => m.UserModule), canActivate: [AuthGuardService] },
  { path: 'webshop', redirectTo: 'user' }, //EK-443
  { path: 'winkel', redirectTo: 'user' }, //EK-443

  //{ path: 'personalmenu', loadChildren: () => import('../personalmenuModule/personalmenu.module').then(m => m.PersonalmenuModule), canActivate: [AuthGuardService] },
  { path: 'personalmenu', loadChildren: () => import('../orderflowModule/orderflow.module').then(m => m.OrderflowModule), canActivate: [AuthGuardService] },
  { path: 'mijn-persoonlijk-menu', redirectTo: '/personalmenu' },
  { path: 'bestellen', redirectTo: '/personalmenu' },

  { path: 'biologischewebwinkel', loadChildren: () => import('../singleorderModule/singleorder.module').then(m => m.SingleorderModule) },
  { path: 'organicwebshop', loadChildren: () => import('../singleorderModule/singleorder.module').then(m => m.SingleorderModule) },
  { path: 'boutiqueenlignebio', loadChildren: () => import('../singleorderModule/singleorder.module').then(m => m.SingleorderModule) },

  //{ path: 'bestellen', loadChildren: () => import('../orderflowModule/orderflow.module').then(m => m.OrderflowModule) },

  { path: 'telemarketing', loadChildren: () => import('../telemarketingModule/telemarketing.module').then(m => m.TelemarketingModule), canActivate: [AuthGuardService] },

  { path: ':type/id/:id', component: StaticpageComponent }, // technical urls
  // handle ALL back end created (seo) urls
  { path: '**', component: StaticpageComponent }, // StaticPage known all entity types


];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }


