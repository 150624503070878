<div class="flex flex-col items-center min-h-fit pt-[25px] md:py-[50px] bg-e-white">

  <h1 class="mt-3 text-center mb-3">{{ 'login.reset_password' | translate }}</h1>

  <form (ngSubmit)="resetpassword()" [formGroup]="resetpasswordForm" class="max-w-sm mt-[25px]" id="resetpasswordform">

      <span class="error" @collapse *ngIf="resetpassword_failed">{{'login.resetpassword_failed' | translate}}</span>
      <span class="error" @collapse *ngIf="resetpassword_success">{{'login.resetpassword_success' | translate}}</span>
      <div class="form-field">
        <label>{{'common.new_password' | translate}}</label>
        <input formControlName="password" id="password" name="new_password" placeholder="{{'common.new_password' | translate}}" required type="{{showpassword? 'text': 'password'}}">
        <button (click)="showpassword = !showpassword" class="absolute right-[15px] top-[35px] z-[1]"><i class="text-[24px]" [ngClass]="showpassword ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'"></i></button>        
        <span class="error" *ngIf="hasError( 'password', 'required')">{{'validation.required' | translate}}</span>
        <span class="error" *ngIf="hasError('password', 'minlength')">{{'validation.password_minlength' | translate}}</span>
      </div>
      <div class="form-field">
        <label>{{'common.password_repeat' | translate}}</label>
        <input formControlName="password_repeat" id="password_repeat" name="password_repeat" placeholder="{{'common.password_repeat' | translate}}" required type="{{ showpassword ? 'text': 'password'}}">
        <button (click)="showpassword = !showpassword" class="absolute right-[15px] top-[35px] z-[1]"><i class="text-[24px]" [ngClass]="showpassword ? 'bi bi-eye-fill' : 'bi bi-eye-slash-fill'"></i></button>        
        <span class="error" *ngIf="hasError('password_repeat', 'required')">{{'validation.required' | translate}}</span>
        <span class="error" *ngIf="hasError('password_repeat', 'notsame')">{{'validation.password_match' | translate}}</span>
      </div>


    <button class="button-base rounded-lg w-full mt-[25px] disabled:opacity-25" [disabled]="!resetpasswordForm.valid" id="resetpassword-button" type="submit">{{'login.reset_password_button' | translate}}</button>

  </form>
</div>

<app-footer></app-footer>





