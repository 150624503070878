
<div [formGroup]="contactform" id="contactform" class="flex justify-center">
  <div class="flex flex-col px-2 rounded-lg max-w-[500px]">
    <h2>{{'contactform.contactform'| translate}}</h2>
    <div class="form-field ">
      <label>{{'contactform.category.title' | translate}}</label>
      <select formControlName="subject" id="subject" type="text">
        <option *ngFor="let category of categories" value="{{'contactform.category.' + category |translate}}">
          {{'contactform.category.' + category |translate}}
        </option>
      </select>
      <small class="error" [@collapse] *ngIf="hasError('subject', 'required')">{{'validation.required' | translate}}</small>
    </div>

    <div class="form-field">
      <label>{{'contactform.email' | translate}}*</label>
      <input formControlName="email" id="email" name="email" required type="email">
      <small class="error" [@collapse] *ngIf="hasError('email', 'required')">{{'validation.required' | translate}}</small>
      <small class="error" [@collapse] *ngIf="hasError('email', 'email_invalid')">{{'validation.email' | translate}}</small>
    </div>
    <div class="form-field">
      <label>{{'contactform.name' | translate}}*</label>
      <input formControlName="name" id="name" name="name" required type="text">
      <small class="error whitespace-nowrap" [@collapse] *ngIf="hasError('name', 'required')">{{'validation.required' | translate}}</small>
    </div>
    <div class="form-field">
      <label>{{'contactform.description' | translate}}*</label>
      <textarea formControlName="description" id="description" name="description" class="h-[175px]"></textarea>
      <small class="error" [@collapse] *ngIf="hasError('description', 'required')">{{'validation.maxlength_value' | translate }}</small>
      <small class="error" [@collapse] *ngIf="hasError('description', 'maxlength')">{{'validation.maxlength_value' | translate: {value:500} }}</small>
    </div>

    <button class="button-cta rounded-lg mt-3" [disabled]="!contactform.valid" (click)="sendContactform()">{{'contactform.send' | translate}}</button>
  </div>
  
</div>
