import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from '../../_environments/environment';
import { CustomerService } from '../../_service/CustomerService';
import { collapse } from '../../_theme/animations';
import { MyValidators } from '../../_validators/MyValidators';


//const DEFAULT_DURATION = 150;

@Component({
    selector: 'contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss'],
    animations: [collapse    ],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, NgClass, NgFor, TranslateModule]
})
export class ContactformComponent implements OnInit, OnDestroy {

  environment = environment;

  contactform = new FormGroup({
    email: new FormControl(null, Validators.required, this._myValidators.Email),
    name: new FormControl(null, Validators.required),
    subject: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    priority: new FormControl(1),
    status: new FormControl(2),
  });

  categories = [
    "DELIVERY",
    "NEW_CUSTOMER",
    "RECIPE",
    "CALLME",
    "COMPLAINT",
    "PRODUCT_DELIVERY_ERROR",
    "BOX_DELIVERY_ERROR"

  ]

  private unsubscribe = new Subject<void>();

  constructor(
    private _customerService: CustomerService,
    private _myValidators: MyValidators,
    public fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this._customerService.customerSubject.subscribe(res => {
      if (res) {
        this.contactform.get('email').setValue(res.email);
        this.contactform.get('name').setValue((res.firstname ? res.firstname : "") + (res.prefix ? " " + res.prefix : "") + (res.lastname ? " " + res.lastname : ""))
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  hasError = (controlName: string, errorName: string) => {
    if (this.contactform.get(controlName).touched)
      return this.contactform.get(controlName).hasError(errorName);
  }

  sendContactform() {

    this._customerService.createTicket(this.contactform.getRawValue()).subscribe(res => {
      this.contactform.reset();
    })
    this.contactform.reset();
  }



}
