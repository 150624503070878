import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
// import {TranslateService} from '@ngx-translate/core';
import { NgClass, NgIf, isPlatformBrowser } from '@angular/common';
import { } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { GlobalService } from 'src/_service/GlobalService';
import { environment } from '../../_environments/environment';
import { AuthService } from '../../_service/AuthService';
import { WINDOW } from '../../_service/WindowService';
import { collapse } from '../../_theme/animations';
import { MyValidators } from '../../_validators/MyValidators';
import { FooterComponent } from '../../sharedModule/footer/footer.component';

const DEFAULT_DURATION = 250

@Component({
    selector: 'app-resetpassword',
    templateUrl: './resetpassword.component.html',
    styleUrls: ['./resetpassword.component.scss'],
    animations: [collapse],
    standalone: true,
    imports: [ReactiveFormsModule, NgIf, NgClass, FooterComponent, TranslateModule]
})
export class ResetpasswordComponent implements OnInit, OnDestroy {

  resetpasswordForm: FormGroup;
  key: string;
  resetpassword_failed: boolean;
  resetpassword_success: boolean = false;

  showpassword: boolean;

  timer: any;

  constructor(
    // public translate: TranslateService,
    private _authService: AuthService,
    public router: Router,
    // private _translate: TranslateService,
    private _myValidators: MyValidators,
    public activatedRoute: ActivatedRoute,
    private _globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {
  }

  ngOnInit() {

    this._globalService.setTranslatedTitle('login.forgotpassword');

    this.resetpasswordForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      password_repeat: new FormControl('', [Validators.required, this._myValidators.ConfirmPassword]),
    });
    this.resetpasswordForm.valueChanges.subscribe(value => {
      this.resetpassword_failed = false;
    })


    this.activatedRoute.queryParams.pipe(
      filter((params: any) => params.key)
    ).subscribe(queryParams => {
      this.key = queryParams.key;
    });

  }

  public hasError = (controlName: string, errorName: string) => {
    if (this.resetpasswordForm.controls[controlName].dirty)
      return this.resetpasswordForm.controls[controlName].hasError(errorName);
  }

  public resetpassword() {
    if (!this.resetpasswordForm.valid) {
      return;
    }
    this._authService.changepasswordkey(this.resetpasswordForm.get('password').value, this.key).subscribe(
      result => {
        this.resetpassword_failed = false;
        this.resetpassword_success = true;
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(() => {
            if (result === 'ADMIN') {
              this.window.location.href = String(environment.etna);
            } else {
              this.router.navigate(['./login']);
            }
          }, 4000);
        }
      },
      error => {
        this.resetpassword_failed = true;
        this.resetpassword_success = false;
      }

    );
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

}
