import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { LowerCasePipe, NgFor, NgIf, formatDate } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject, first, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { CustomerAllergen, CustomerLifestyle } from '../../_model/customer';
import { Allergen, Recipe } from '../../_model/recipe';
import { AuthService } from '../../_service/AuthService';
import { CmsService } from '../../_service/CmsService';
import { DeliverydayService } from '../../_service/DeliverydayService';
import { DrawerService, Drawerposition } from '../../_service/DrawerService';
import { GlobalService } from '../../_service/GlobalService';
import { LifestyleService } from '../../_service/LifestyleService';
import { LinkService } from '../../_service/LinkService';
import { RecipeService } from '../../_service/RecipeService';
import { SubscriptionService } from '../../_service/SubscriptionService';
import { WINDOW } from '../../_service/WindowService';
import { FooterComponent } from '../../sharedModule/footer/footer.component';
import { LifestyleselectionComponent } from '../../sharedModule/lifestyleselection/lifestyleselection.component';
import { LocalizedDatePipe } from '../../sharedModule/pipe/localizedDate.pipe';
import { RecipeComponent } from '../../sharedModule/recipe/recipe.component';
import { RecipecardComponent } from '../../sharedModule/recipecard/recipecard.component';
import { RecipelistComponent } from '../../sharedModule/recipelist/recipelist.component';
import { CMSpageComponent } from '../cmspage/cmspage.component';
import { CustomerService } from '../../_service/CustomerService';


@Component({
    selector: 'app-weekrecipes',
    templateUrl: './weekrecipes.component.html',
    styleUrls: ['./weekrecipes.component.scss'],
  standalone: true,
  providers: [DeliverydayService, SubscriptionService, LocalizedDatePipe],
  imports: [
    NgFor, NgIf, RouterLink,
    MatSelect, MatOption,
    ReactiveFormsModule, TranslateModule,
    RecipelistComponent, RecipecardComponent, RecipeComponent, FooterComponent, LifestyleselectionComponent, CMSpageComponent,
    LowerCasePipe, LocalizedDatePipe,
  ]
})
export class WeekrecipesComponent implements OnInit, OnDestroy {

  _env = environment;

  topstory: any;
  bottomstory: any;
  topstory_slug = 'general/menus-page-top'
  bottomstory_slug = 'general/menus-page-bottom'

  nextThursday: any;
  nextWeekWednesday: any;

  recipes: Recipe[];
  scrollStrategy: ScrollStrategy;

  shownRecipe: Recipe;

  bottomsheetRef: MatBottomSheetRef;
  bottomSheetOpened = false;
  drawerId = 'recipedetails-drawer-weekrecipes';
  currentUrl: string;

  dialogRef: any;

  allergens: string[];

  lifestylesCtrl = new FormControl(null)
  allergensCtrl = new FormControl([]);

  recipedetailsdrawerShown = false;
  @ViewChild('recipedetailsdrawer', { static: true }) public recipedetailsdrawer;
  offcanvasDrawer: NgbOffcanvasRef;
  constructor(
    public activatedRoute: ActivatedRoute,
    public drawerService: DrawerService,
    public dialog: MatDialog,
    public translate: TranslateService,

    private _customerService: CustomerService,
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    private _recipeService: RecipeService,
    private _titleService: Title,
    private _cmsService: CmsService,
    private readonly sso: ScrollStrategyOptions,
    private _localedatepipe: LocalizedDatePipe,
    private _globalService: GlobalService,
    private _linkService: LinkService,
  ) {
  }

  unsubscribe: Subject<void> = new Subject<void>();

  ngOnInit() {


    const page_lang = this._globalService.getLanguage();

    this._cmsService.getStory(this.topstory_slug, { resolve_links: "1", language: page_lang }).then(data => {
      this.topstory = data.story;

      const meta_data = !!this.topstory.content.meta[0] ? this.topstory.content.meta[0] : { title: 'Ekomenu', description: 'Ekomenu', meta_image: { filename: '' } };
      if (!!meta_data) {
        const title = !!this.topstory.content.meta_tags?.title ? this.topstory.content.meta_tags.title : meta_data.meta_title;
        const description = !!this.topstory.content.meta_tags?.description ? this.topstory.content.meta_tags.description : meta_data.meta_description;
        const image_src = !!this.topstory.content.meta_tags?.og_image ? this.topstory.content.meta_tags.og_image : meta_data.meta_image.filename;

        this._linkService.removeMetaData();

        this._linkService.setStaticpageSeoData(title, description, image_src);
      }


    }).catch(error => {
      console.error(error)
    })

    this._cmsService.getStory(this.bottomstory_slug, { resolve_links: "1", language: page_lang }).then(data => {
      this.bottomstory = data.story;
    }).catch(error => {
      console.error(error)
    })

    this.drawerService.setDrawer(Drawerposition.bottom, this.recipedetailsdrawer, this.drawerId, 'overflow-y-auto bg-white md:mx-[50px] h-[85vh]');
    this.scrollStrategy = this.sso.noop();
   
    this.allergens = this._recipeService.getAllergens();

    const lifestyleid = this._customerService.getCustomerLifestyles.value.map((l: CustomerLifestyle) => l.lifestyleid)[0];
    this.lifestylesCtrl.patchValue(lifestyleid);

    const allergens = this._customerService.getAllergenFormControls.value.map((l: CustomerAllergen) => l.name);
    this.allergensCtrl.patchValue(allergens);

    this.lifestylesCtrl.valueChanges.subscribe((value: number) => {
      this._customerService.addLifeStyle(new CustomerLifestyle(null, value))
    })
    this.allergensCtrl.valueChanges.subscribe((value: Allergen[]) => {
      this._customerService.getAllergenFormControls.clear();
      this._customerService.getAllergenFlowFormControls.clear();
      value.forEach(val => {
        this._customerService.addAllergen(new CustomerAllergen(null, val))
      })
    })


    this.activatedRoute.queryParams.pipe(first()).subscribe(params => {
      let dateToday
      if (params.date) {
        dateToday = new Date(params.date);
      } else {
        dateToday = this._globalService.addDays( new Date(), 0); //HH 05-02-2024 was 7, but then it went a week too far away
      }

      const dateTodayWeekday = formatDate(dateToday, 'c', ɵDEFAULT_LOCALE_ID);

      let dateoffset = 4 - Number(dateTodayWeekday);
      if (dateoffset > 0) {
        this.nextThursday = formatDate(this._globalService.addDays(dateToday, dateoffset + 7), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
      } else {
        this.nextThursday = formatDate(this._globalService.addDays(dateToday, dateoffset), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
      }
      this.nextWeekWednesday = formatDate(this._globalService.addDays(new Date(this.nextThursday),6), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

      this.setTitle();
    })

  }

  ngOnDestroy() {
    this.drawerService.clearDrawer(this.drawerId);
    this.unsubscribe.next();
    this._linkService.removeLinkTag('rel="alternate"')
    this._linkService.removeLinkTag('rel="canonical"')
    this._linkService.removeMetaData();
  }

  nextweek() {
    this.nextThursday = formatDate(this._globalService.addDays(new Date(this.nextThursday), 7), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    this.nextWeekWednesday = formatDate(this._globalService.addDays(new Date(this.nextThursday), 6), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

    this._router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { date: this.nextThursday },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.setTitle();
  }
  previousweek() {
    this.nextThursday = formatDate(this._globalService.addDays(new Date(this.nextThursday), -7), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
    this.nextWeekWednesday = formatDate(this._globalService.addDays(new Date(this.nextThursday), 6), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

    this._router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { date: this.nextThursday },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    this.setTitle();
  }

  setTitle() {
    this.translate.get('weekrecipes.recipes_for').subscribe(name => {
      let complete_title = '';
      if( environment.title_prefix ){
        complete_title += environment.title_prefix + ' ';
      }
      
      complete_title += name + ' ' + this._localedatepipe.transform(this.nextThursday, 'd MMM') + ' - ' + this._localedatepipe.transform(this.nextWeekWednesday, 'd MMM');
      
      if( environment.title_postfix ){
        complete_title += ' ' + environment.title_postfix;
      }
      this._titleService.setTitle(complete_title);
    });
  }

  toggleRecipeDrawer(recipe: Recipe = null) {
    if (!this.recipedetailsdrawerShown && recipe) {
      this.shownRecipe = recipe;
      this.recipedetailsdrawerShown = true
      this.offcanvasDrawer = this.drawerService.toggle('recipedetails-drawer-weekrecipes');
      this.offcanvasDrawer.dismissed.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.recipedetailsdrawerShown = false;
        this.shownRecipe = null;
      })
    } else {
      this.offcanvasDrawer.dismiss();
      this.recipedetailsdrawerShown = false;
      this.shownRecipe = null;
    }
  }





}
