import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../_service/Toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '../../_service/OrderService';
import { SubscriptionService } from '../../_service/SubscriptionService';
import { LocalstorageService } from '../../_service/LocalstorageService';
import { Snackbarerrorformat, Snackbarformat } from '../../_theme/snackbarformats';


@Component({
    selector: 'app-PaymentSuccess',
    template: 'PAYMENT SUCCESS',
    standalone: true
})
export class PaymentSuccessComponent implements OnInit {

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
  private _toastService: ToastService,

    private _translate: TranslateService,
    private _orderService: OrderService,
    private _subscriptionService: SubscriptionService,
    private _localStorage: LocalstorageService
  ) {

  }
  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
    ).subscribe(queryParams => {
      console.log('paymentsuccess queryparams', queryParams);
      const mandatenumber = queryParams.mandatenumber;
      const status = queryParams.status;

      let translation = null
      let snackformat = null

      switch (status) {
        case 'fail':
          translation = { text: 'checkout.the signing of the mandate failed', button: 'common.ok_dismiss' }
          snackformat = Snackbarerrorformat
          break;
        case 'cancel':
          translation = { text: 'checkout.the signing of the mandate was cancelled', button: 'common.ok_dismiss' }
          snackformat = Snackbarerrorformat
          break;
        case 'expired':
          translation = { text: 'checkout.the signing of the mandate was expired', button: 'common.ok_dismiss' }
          snackformat = Snackbarerrorformat
          break;
        case 'success':
          translation = { text: 'checkout.the mandate is successfully created!', button: 'common.ok_dismiss' }
          snackformat = Snackbarformat
          
          break;
      }
      if (translation)
        this._translate.get(translation).subscribe(res => {
        this._toastService.open
(res.text, res.button, snackformat);
      })

      let url = this._localStorage.getItem('paymentReturnUrl');
      this._localStorage.removeItem('paymentReturnUrl')

      if (!url) {
        url = '/'
        this._orderService.clearNewOrderForm();
      }

      console.log(url, mandatenumber, status)

      this.router.navigate([url], { queryParams: {status: status}});

    });
      



  }

}
