import { Component, OnInit, ViewChild, Inject, PLATFORM_ID, Input } from '@angular/core';
import { isPlatformBrowser, NgClass, NgIf, NgFor, AsyncPipe, JsonPipe } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { WINDOW } from 'src/_service/WindowService';
import { AuthService } from 'src/_service/AuthService';
import { Drawerposition, DrawerService } from 'src/_service/DrawerService';
import { GlobalService } from 'src/_service/GlobalService';
import { environment } from 'src/_environments/environment';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Language, Sourcetype } from '../../_model/shared';

@Component({
  selector: 'drawer',
  templateUrl: './drawer.component.html',
  standalone: true,
  imports: [
    NgClass, NgIf, RouterLink, NgFor,
    ReactiveFormsModule, TranslateModule,
    AsyncPipe, JsonPipe
  ],
})

export class DrawerComponent implements OnInit {

  @ViewChild('drawer', { static: true }) public drawer;
  preferredlanguageForm = new FormControl('');
  openMenu = false;
  env = { ...environment };
  languages: string[];
  current_lang: Language;
  // TODO: get these from CMS. should be manageable
  defaultLinks = [
    { label: 'home.drawer.howitworks', route: 'home.drawer.url.howitworks', showInLanguage: [Language.NL, Language.EN, Language.FR] },
    { label: 'home.drawer.impact', route: 'home.drawer.url.impact', showInLanguage: [Language.NL, Language.EN, Language.FR] },
    { label: 'home.drawer.organicwebshop', route: 'home.drawer.url.organicwebshop', showInLanguage: [Language.NL, Language.EN, Language.FR] },
    { label: 'home.drawer.feelgood', route: 'home.drawer.url.feelgood', showInLanguage: [Language.NL] },
    { label: 'home.drawer.x_benefits', route: 'home.drawer.url.x_benefits', showInLanguage: [Language.NL, Language.EN, Language.FR] },
    { label: 'user.contact', route: '/contact', showInLanguage: [Language.NL, Language.EN, Language.FR] },
    { label: '' }

  ]
  isAuthenticated = this._authService.isAuthenticatedSubject;

  constructor(
    private _authService: AuthService,
    private _globalService: GlobalService,
    private _translateService: TranslateService,
    public drawerService: DrawerService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,

  ) {
  }

  ngOnInit() {
    this.drawerService.setDrawer(Drawerposition.start, this.drawer, 'left-drawer');
    this.languages = this._globalService.getLanguagecodes();

    this.current_lang = Language[this._globalService.getLanguage().toUpperCase()];

    this.preferredlanguageForm.setValue(this.current_lang);
    this.preferredlanguageForm.valueChanges.subscribe(value => {

      this._globalService.setLanguage(Sourcetype.CUSTOMER, Language[value]);
      const tld = this._globalService.getTld()

      const trans_url = this.window.document.querySelector(`link[hreflang^=${value.toLowerCase()}-${tld}]`);
      setTimeout(() => {
        // prevent the reload before we have completed the lang pref storage
        this.reloadPage(!!trans_url && !!trans_url['href'] && !trans_url['href'].endsWith('/home') ? trans_url['href'] : null);
      }, 250);

    });
    this._translateService.onLangChange.subscribe(val => {
      //console.log(val);
      this.preferredlanguageForm.setValue(val.lang.toUpperCase(), { emitEvent: false });
    });
  }
  reloadPage(path) {
    if (isPlatformBrowser(this.platformId)) {
      if (!!path) {
        // TODO: use router nagivation if we stay in the same domain ?
        this.window?.location.replace(path);
      } else {
        this.window?.location.reload();
      }
    }
  }

  logout() {
    this._authService.logout();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.router.navigate(['logout']);
      }, 0);
    }
  }

}
